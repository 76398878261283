import GenericPage from 'components/GenericPage'
import Layout from 'components/layouts/Layout'
import getSettings from 'lib/settings'
import { getSession } from 'next-auth/client'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

type PageProps = {
  slug: string
}

const Page = (props: PageProps) => {
  return <GenericPage slug={`${props.slug}`} />
}

Page.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>
}

export default Page

export const getServerSideProps: any = async (context: any) => {
  return {
    props: {
      session: await getSession(context),
      slug: context.query.slug as string,
      ...(await serverSideTranslations(context.locale || '')),
      ...(await getSettings()),
    },
  }
}
