import { useQuery } from '@apollo/client'
import Container from 'components/Container'
import EditorJsBlocks from 'components/EditorJsBlocks'
import { GET_PAGE } from 'gql/page'
import useHandleError from 'hooks/useHandleError'
import Error from 'next/error'
import Head from 'next/head'
import React from 'react'
import { Page } from 'types'
import { useSettings } from '../context/SettingsProvider/SettingsContext'

const GenericPage = ({ slug }: { slug: string }) => {
  const { data, loading, error } = useQuery<{ page: Page }>(GET_PAGE, {
    variables: {
      slug,
    },
  })
  const setting = useSettings()

  const ret = useHandleError(loading, error)
  if (ret !== true) {
    return ret
  }

  if (data && !data.page) {
    return <Error statusCode={404} />
  }
  return (
    <div data-testid='content'>
      <Head>
        <title>{data?.page.title}</title>
      </Head>
      <Container>
        <h1 className='text-4xl font-extrabold font-heading pb-6'>
          {data?.page.title}
        </h1>
        {data?.page.contentJson && (
          <section>
            <EditorJsBlocks data={data?.page.contentJson} />
          </section>
        )}
      </Container>
    </div>
  )
}

export default GenericPage
