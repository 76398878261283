import { ApolloError, ServerError, ServerParseError } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import Container from 'components/Container'
import { Loading } from 'components/Loading'
import { signIn, signOut } from 'next-auth/client'
import { default as NextError } from 'next/error'
import React, { useEffect } from 'react'

// Check if this error is a network error
function isHttpError(
  networkError: Error | ServerParseError | ServerError | null | undefined,
): networkError is ServerParseError | ServerError {
  return (
    networkError !== null &&
    typeof networkError !== 'undefined' &&
    (typeof (networkError as ServerParseError)?.response !== 'undefined' ||
      typeof (networkError as ServerError)?.response !== 'undefined')
  )
}

const useHandleError = (loading: boolean, error: ApolloError | undefined) => {
  const unauthenticated =
    (isHttpError(error?.networkError) &&
      error?.networkError.statusCode === 401) ||
    error?.message === 'Unauthenticated.'

  // Handle redirection nicely
  useEffect(() => {
    // Log error to sentry (if enabled)
    if (error && process.env.NEXT_PUBLIC_SENTRY_DSN) {
      Sentry.captureException(error)
    }

    // if server session expired, we need to sign out
    if (unauthenticated) {
      signOut({ redirect: false }).then(() => signIn())
    }
  }, [error])

  if (loading) {
    return (
      <Container>
        <Loading isLoading={loading} />
      </Container>
    )
  }

  // Render error
  if (error) {
    if (unauthenticated) {
      // handled by useEffect, do not display anything while redirecting
      return null
    }
    return <NextError statusCode={500} />
  }

  // no error, do not interrupt page rendering
  return true
}

export default useHandleError
